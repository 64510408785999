<template>
<div>
    <v-card class="mx-auto" outlined v-if="status != 'inicio'">
        <v-container>
            <div v-if="registroNuevo == 'Si' || status == 'nuevo'">
                <p class="text-center font-20 azul">Regístrate y adquiere tu Protección Despensa</p>
                <v-form v-model="valid" ref="formClienteNuevo" v-on:submit.prevent="">
                    <v-text-field v-model="persona.nombres" color="#0C55A6" :rules="reglas.campo" counter="80" label="Nombre(s)" outlined dense></v-text-field>
                    <v-text-field v-model="persona.apPat" color="#0C55A6" :rules="reglas.campo" counter="80" label="Apellido paterno" outlined dense></v-text-field>
                    <v-text-field v-model="persona.apMat" color="#0C55A6" :rules="reglas.campo" counter="80" label="Apellido materno" outlined dense></v-text-field>
                    <v-text-field v-model="persona.correo" color="#0C55A6" :rules="reglas.correo" counter="150" label="Correo" outlined dense></v-text-field>
                    <v-text-field v-model="persona.telefono" color="#0C55A6" v-mask="'##########'" :rules="reglas.telefono" counter="10" label="Teléfono" outlined dense></v-text-field>
                    <v-select v-model="persona.genero" :items="['Masculino', 'Femenino']" dense label="Género" outlined :rules="reglas.requerido"></v-select>
                    <v-text-field v-model="persona.fechaNacimiento" v-mask="'####-##-##'" placeholder="aaaa-mm-dd" color="#0C55A6" :rules="reglas.requerido" label="Fecha de nacimiento" outlined dense></v-text-field>
                </v-form>
            </div>
            <div v-else>
                <div v-if="status == 'Posterior'">
                    <p class="text-center font-20 azul">
                        Tu protección continúa activa hasta <b class="naranja">{{ cliente.cliente.finVigencia }}</b>, cuando tu periodo esté por finalizar
                        te notificaremos para que puedas adquirir tu Protección Despensa.
                    </p>
                </div>
                <div v-if="status == 'Pendiente'">
                    <p class="text-center font-20 azul">Tus datos</p>
                    <p class="font-18 azul">Nombre:</p>
                    <p class="font-16">{{ cliente.cliente.nombres + ' ' + cliente.cliente.apPat + ' ' + cliente.cliente.apMat }}</p>
                    <p class="font-18 azul">Fecha nacimiento:</p>
                    <p class="font-16">{{ cliente.cliente.fechaNacimiento }}</p>
                    <p class="font-18 azul">Teléfono:</p>
                    <p class="font-16">{{ cliente.cliente.numeroTelefono }}</p>
                </div>
                <div v-if="status == 'Pagado'">
                    <p class="text-center font-20 azul">Datos de tu compra</p>
                    <p class="font-18 azul">Referencia de pago:</p>
                    <p class="font-16">{{ cliente.referencia }}</p>
                    <p class="font-18 azul">Total a cobrar mensual:</p>
                    <p class="font-16">${{ cliente.total }}</p>
                    <p class="font-18 azul">Tarjeta:</p>
                    <p class="font-16">{{ cliente.tarjeta }}</p>
                    <v-btn block outlined color="#0C55A6" :href="cliente.url" target="_blank">
                        Cancelar suscripción de pagos
                    </v-btn>
                </div>
                <v-checkbox v-model="registroNuevo" @change="nuevoRegistro()" label="Deseo comprar el producto para alguien más." color="#0C55A6" value="Si"></v-checkbox>
            </div>
        </v-container>
        <v-card-actions>
            <v-btn @click="generaClienteNuevo()" color="#0C55A6" dark rounded small block v-if="registroNuevo == 'Si' || status == 'nuevo'">
                Contrata aquí por $23 al mes
            </v-btn>
            <v-btn @click="generaPago()" color="#0C55A6" dark rounded small block v-if="status == 'Pendiente'">
                Contrata aquí por $23 al mes
            </v-btn>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
import axios from "axios";
import {
    backend
} from "@/back/global.js";

export default {
    name: "Cardclientepago",
    props: ["cliente", "idCliente", "status"],
    data() {
        return {
            registroNuevo: "No",
            valid: true,
            persona: {
                nombres: "",
                apPat: "",
                apMat: "",
                correo: "",
                telefono: "",
                fechaNacimiento: "",
                genero: ""
            },
            reglas: {
                requerido: [
                    value => !!value || "Campo requerido.",
                ],
                campo: [
                    value => !!value || "Campo requerido.",
                    value => value.length <= 80 || "El campo no puede ser mayor a 80 caracteres."
                ],
                correo: [
                    value => !!value || "Campo requerido.",
                    value => value.length <= 150 || "El campo no puede ser mayor a 150 caracteres."
                ],
                telefono: [
                    value => !!value || "Campo requerido.",
                    value => value.length <= 10 || "El campo no puede ser mayor a 10 digítos.",
                    value => /^([0-9])*$/.test(value) || "Solo se permiten números"
                ],
            }
        }
    },
    methods: {
        generaClienteNuevo() {
            if (!this.$refs.formClienteNuevo.validate()) {
                return false;
            }
            this.$store.state.overlay = true;
            axios({
                    method: "post",
                    url: backend.url + "despensa/generaClienteNuevo",
                    headers: backend.headers,
                    data: {
                        nombres: this.persona.nombres,
                        apPat: this.persona.apPat,
                        apMat: this.persona.apMat,
                        correo: this.persona.correo,
                        telefono: this.persona.telefono,
                        fechaNacimiento: this.persona.fechaNacimiento,
                        genero: this.persona.genero
                    }
                })
                .then(response => {
                    let token = this.$jwt.decode(response.data.token);
                    switch (token.status) {
                        case 1:
                            this.ventanaPago(token.data.referencia, token.data.costo, token.data.producto, token.data.cliente);
                            break;
                        case 0:
                            this.$snotify.error(token.msg, "Aviso", {
                                timeout: 3000,
                                showProgressBar: true,
                                position: "rightTop"
                            });
                            break;
                    }
                })
                .catch(error => {
                    this.$snotify.error('No pudimos comenzar tu proceso de pago ' + error, "Aviso", {
                        timeout: 3000,
                        showProgressBar: true,
                        position: "rightTop"
                    });
                }).finally(() => {
                    this.$store.state.overlay = false;
                });
        },
        generaPago() {
            if (!this.idCliente) {
                this.$snotify.error("No podemos procesar tu solicitud intenta de nuevo mas tarde.", "Aviso", {
                    timeout: 3000,
                    showProgressBar: true,
                    position: "rightTop"
                });
                return false;
            }
            this.$store.state.overlay = true;
            axios({
                    method: "post",
                    url: backend.url + "despensa/generaReferencia",
                    headers: backend.headers,
                    data: {
                        idCliente: this.idCliente
                    }
                })
                .then(response => {
                    let token = this.$jwt.decode(response.data.token);
                    switch (token.status) {
                        case 1:
                            this.ventanaPago(token.data.referencia, token.data.costo, token.data.producto, token.data.cliente);
                            break;
                        case 0:
                            this.$snotify.error(token.msg, "Aviso", {
                                timeout: 3000,
                                showProgressBar: true,
                                position: "rightTop"
                            });
                            break;
                    }
                })
                .catch(error => {
                    this.$snotify.error('No pudimos comenzar tu proceso de pago ' + error, "Aviso", {
                        timeout: 3000,
                        showProgressBar: true,
                        position: "rightTop"
                    });
                }).finally(() => {
                    this.$store.state.overlay = false;
                });
        },
        ventanaPago(referencia, costo, producto, cliente) {
            var pago = {
                total: costo,
                reference: referencia,
                cust: {
                    fname: cliente.nombres,
                    mname: cliente.apPat,
                    lname: cliente.apMat,
                    email: cliente.correo,
                    phone: "52" + cliente.numeroTelefono
                },
                items: [{
                    name: producto,
                    qty: 1,
                    desc: producto,
                    unitPrice: costo
                }],
                recurring: {
                    interval: "month",
                    total: costo
                },
            };
            var SW = new BwGateway({
                sandbox: backend.sandbox,
                user: backend.usuBw,
                title: "Protección Chedraui",
                concept: producto,
                currency: 'MXN',
                time: 900,
                paymentOptions: 'visa,mastercard',
                reviewOrder: true,
                showShipping: false,
                notifyUrl: backend.urlNotify,
                successPage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onSuccess: function (data) {},
                pendingPage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onPending: function (data) {},
                challengePage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onChallenge: function (data) {},
                errorPage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onError: function (data) {},
                onCancel: function (data) {}
            });
            SW.pay(pago);
        }
    }
}
</script>
