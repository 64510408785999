<template>
<div>
    <v-container fluid class="pa-0 parentDiv">
        <v-img class="bannerProducto" :src="require('@/assets/img/despensa/'+banner+'.png')" />
        <RouterLink to="/">
            <v-img class="backHome" max-width="40" :src="require('@/assets/img/btnHome.png')" />
        </RouterLink>
    </v-container>
    <v-container mt-10></v-container>
    <v-container>
        <v-img max-width="350" class="mx-auto" :src="require('@/assets/img/logos/LogoDespensa.png')" />
    </v-container>
    <v-container>
        <v-layout wrap>
            <v-flex xs12 md8 class="mx-auto text-center">
                <v-btn color="#0c55a6" class="btnNoMayuscula" dark small to="/despensa">Más información</v-btn>
                <v-layout wrap>
                    <v-flex xs12 md8 class="mx-auto mt-5">
                        <Cardcliente :cliente="datos" :idCliente="idCliente" :status="status" />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
    <Footer programa="Despensa" />
</div>
</template>

<script>
import Footer from "@/components/FooterPrograma.vue";
import Cardcliente from "@/components/despensa/CardClientePago.vue";

export default {
    name: "Contratadespensa",
    components: {
        Footer,
        Cardcliente
    },
    data: () => ({
        idCliente: "",
        status: "inicio",
        datos: {}
    }),
    mounted() {
        this.$localStorage.remove('token');
        this.status = "nuevo";
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerDespensaMovil';
                case 'sm':
                    return 'BannerDespensaMovil';
                case 'md':
                    return 'BannerDespensa';
                case 'lg':
                    return 'BannerDespensa';
                case 'xl':
                    return 'BannerDespensa';
            }
        }
    }
}
</script>
